.footer-footer {
    color: white;
    display: flex;
    position: relative;
    background-color: #222;
    bottom: 0; 
    height: 200px;
    width: 100%;
    flex-direction: row;  
    align-items: center;
    justify-content: space-evenly;
    border-top: #222 1px solid;
  }
  
  .footer-copyright {
    color: white;
    display: flex;
    position: relative;
    background-color: #222;
    bottom: 0; 
    height: 75px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-top: #222 1px solid;
  }

  @media screen and (max-width: 800px) {
    .footer-footer {
    height: 450px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }
}