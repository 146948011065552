.solutions-body {
    background-color: #fff;
    min-height: 100vh;
    letter-spacing: 0.1px;
    display: flex;
    padding-right: 3vw;
    justify-content: space-evenly;
    flex-direction: column;
    font-size: calc(7px + 2vmin);
    color: #222;
    margin-bottom: 0px;
    flex-wrap: wrap;
    overflow-x: hidden;
  }

.solutions-row {
    background-color: #fff;
    min-height: 25vh;
    padding-top: 5vh;
    padding-right: 3vw;
    padding-bottom: 5vh;
    padding-left: 3vw;
    width: 100vw;
    border-bottom: solid .5px #ccc;
    flex-wrap: wrap;
  }

.solutions-row2 {
    background-color: #fff;
    min-height: 25vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 3vw;
    padding-left: 3vw;
    width: 100vw;
    border-bottom: solid .5px #ccc;
    flex-wrap: wrap;
  }

.solutions-row3 {
    background-color: #fff;
    min-height: 25vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 3vw;
    padding-left: 3vw;
    width: 100vw;
    border-bottom: none;
    flex-wrap: wrap;
  }