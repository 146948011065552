.store-body {
  background-color: #fff;
  min-height: 100vh;
  letter-spacing: 0.1px;
  display: flex;
  padding-right: 3vw;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: calc(7px + 2vmin);
  color: #222;
  margin-bottom: 0px;
  padding-top: 5vh;
  padding-right: 3vw;
  padding-bottom: 5vh;
  padding-left: 3vw;
  flex-wrap: wrap;
  overflow-x: hidden;
}

:root {
  --primary-background: #fff;
  --primary-text: #222;
  --btn-background: white;
  --btn-color: gray-dark;
  --card-background: #222;
  --card-text: #fff;
  --card-border-radius: 5px;
  --card-button-background: #ffff;
  --card-button-color: #222;
}

/* Buttons */
.btn {
  background-color: var(--btn-background);
  padding: 17px;
  color: var(--btn-color);
  padding: 1rem;
  text-transform: lowercase;
  cursor: pointer;
}

.btn--block {
  display: block;
  width: 100%;
}

/* Cards */
.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-between; /* Updated for even spacing */
}

.cards__item {
  display: flex;
  padding: 1rem;
}

/* Container */
.container {
  width: 100%;
  background: var(--primary-background);
  overflow-x: hidden;
  border-radius: var(--card-border-radius);
  margin-bottom: 15px;
  /* Removed box-shadow property */
}

/* Card Container */
.card__container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  align-items: center;
  justify-items: center; /* Updated for horizontal centering */
  justify-content: space-between;
  color: var(--card-text);
}

/* Card Content */
.card__content {
  width: 100%;
  background: var(--card-background);
  color: var(--card-text);
  border-radius: var(--card-border-radius);
  padding: 1.5rem;
  cursor: pointer;
  max-width: 20vw;
  min-width: 20vw;
  margin-bottom: 0px;
  padding-top: 5vh;
  padding-right: 3vw;
  padding-bottom: 5vh;
  padding-left: 3vw;
  margin: 15px;
  transition: 0.3s all ease-in-out;
  border-left: 8px solid #725346;
}

@media screen and (max-width: 500px) {
  .card__content {
    width: 100%;
    background: var(--card-background);
    color: var(--card-text);
    border-radius: var(--card-border-radius);
    padding: 1.5rem;
    cursor: pointer;
    max-width: 70vw;
    min-width: 70vw;
    margin-bottom: 0px;
    padding-top: 5vh;
    padding-right: 5vw;
    padding-bottom: 5vh;
    padding-left: 5vw;
    transition: 0.3s all ease-in-out;
    border-left: 8px solid #725346;
  }
}

/* Card Header */
.card__header {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

/* Card Button */
.card__button {
  padding: 0.75rem 1.5rem;
  background: var(--card-button-background);
  color: var(--card-button-color);
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

/* Card Button2 */
.card__button2 {
  padding: 0.75rem 1.5rem;
  background: var(--card-button-background);
  color: var(--card-button-color);
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 30vw;
  border: none;
}

.card__button:hover {
  background-color: #725346;
}

.card__button2:hover {
  background-color: #725346;
}

/* Media Queries */
@media screen and (max-width: 800px) {
/* Card Button2 */
.card__button2 {
  padding: 0.75rem 1.5rem;
  background: var(--card-button-background);
  color: var(--card-button-color);
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 60vw;
  border: none;
}
}

/* Card Options */
.card_options {
  width: 100%;
  max-width: 40vw;
  max-height: 80vh; /* Adjust this value to control the max height */
  padding: 1rem;
  color: var(--card-text);
  position: fixed;
  background: var(--card-background);
  flex-direction: column;
  align-items: center;
  justify-items: center;
  display: flex;
  border-radius: var(--card-border-radius);
  overflow-y: auto; /* Enable vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  left: 50%; /* Center horizontally */
  top: 8%;
  transform: translateX(-50%); /* Center horizontally */
  z-index: 10;
}

/* Media Queries */
@media screen and (max-width: 800px) {
  .card_options {
    width: 90%;
    max-width: 90vw;
    max-height: 80vh; /* Adjust this value for smaller screens */
    padding: 1rem;
    color: var(--card-text);
    position: fixed;
    background: var(--card-background);
    flex-direction: column;
    align-items: center;
    justify-items: center;
    display: flex;
    border-radius: var(--card-border-radius);
    overflow-y: auto; /* Enable vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    left: 50%; /* Center horizontally */
    top: 10%;
    transform: translateX(-50%); /* Center horizontally */
    z-index: 10;
  }
}