/* Profile.css */
.profile-body {
  background-color: #fff;
  min-height: 100vh;
  letter-spacing: 0.1px;
  display: flex;
  padding-right: 3vw;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: calc(7px + 2vmin);
  color: #222;
  margin-bottom: 0px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.profile-row {
    background-color: #fff;
    min-height: 25vh;
    padding-top: 5vh;
    padding-right: 3vw;
    padding-bottom: 5vh;
    padding-left: 3vw;
    width: 100vw;
    border-bottom: solid .5px #ccc;
    flex-wrap: wrap;
  }

.profile-row2 {
    background-color: #fff;
    min-height: 25vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 3vw;
    padding-left: 3vw;
    width: 100vw;
    border-bottom: solid .5px #ccc;
    flex-wrap: wrap;
  }

.profile-row3 {
    background-color: #fff;
    min-height: 25vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 3vw;
    padding-left: 3vw;
    width: 100vw;
    border-bottom: none;
    flex-wrap: wrap;
  }

p {
    flex-shrink: 1;
    word-wrap: break-word;
    white-space: normal;
    word-break: break-word;
    padding-right: 6vw;
}

.profile-text-box-label {
  position: absolute;
  left: 18px;
  color: #555;
  font-size: 14px;
}

.profile-text-box-container {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

.profile-text-box-input {
  min-width: 0;
  width: 100%;
  padding: 18px;
  height: 60px;
  padding-top: 30px;
  padding-bottom: 15px;
  margin-right: 6vw;
  border: None;
  border-radius: 5px;
  background-color: #efefef;
  color: #555;
  font-size: 16px;
  box-sizing: border-box;
  opacity: 0.7;
}

.profile-text-box-input-editable {
  min-width: 0;
  width: 100%;
  padding: 18px;
  height: 60px;
  padding-top: 30px;
  padding-bottom: 15px;
  border: None;
  border-radius: 5px;
  background-color: #efefef;
  color: #555;
  font-size: 16px;
  box-sizing: border-box;
  opacity: 0.7;
}     

.profile-edit-button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 17px;
    font-size: 16px;
    margin-right: 6vw;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-left: 10px;
    transition: 0.3s;
  }
  
