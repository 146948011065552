@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300italic,600italic,300,600");

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

html{
  color: #222;
  font-family: "Source Sans Pro", sans-serif;
  scroll-behavior: smooth;
  font-weight: 300;
  background-color: #222;
  font-size: 1rem;
  line-height: 1.65;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-thumb {
    background: #725346;
}
::-webkit-scrollbar-track {
    background: #222;
}

button{
  border-radius: 5px;
  color: white;
  background-color: #222;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 17px;
  transition: 0.3s;
}

button:hover {
  background-color: #725346;
}

ol, ul {
	list-style: none;
}

a {
  margin: 0;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: #725346;
}

p {
 font-size: 1rem;
}

h1 {
  color: #222;
  line-height: 1.5;
}

  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
    text-decoration: none;
  }

  h1.major, h2.major, h3.major, h4.major, h5.major, h6.major {
    border-bottom: solid 1px #fff;
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -ms-max-content;
    width: max-content;
    padding-bottom: 0.5rem;
    margin: 0 0 2rem 0;
  }

  h1 {
    font-size: 1.75rem;
    line-height: 1.5;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.3;
  }

  h3 {
    font-size: 1rem;
    
  }

  h4 {
    font-size: 0.8rem;
  }

  h5 {
    font-size: 0.7rem;
  }

  h6 {
    font-size: 3rem;
    margin: 10vh 0px 0px;
  }

@keyframes page-load {
  from {
      width:1;
  }
  to {
      width:100;
  }
}

.page-loading::before {
  content:" ";
  display:block;
  position:fixed;
  z-index:10;
  height:2px;
  width:100%;
  top:0;
  left:0;
  background-color:#0071ce;
  animation: page-load ease-out 5s;
}

