.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222; /* Set your desired background color */
    min-width: 0;
    padding: 15px 10px 10px;
    border: None;
    border-radius: 5px;
  }
  
  .logo {
    color: #fff; /* Set your desired text color */
    margin-left: 1vw;
  }
  
  .header-menu {
    color: #fff; /* Set your desired text color */
    font-size: 24px;
    cursor: pointer;
    margin-right: 1vw;
    margin-left: 20px;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
  }

  .header-menu-icon {
    position: relative;
    top: -5px;
  }

  .header-menu-icon :hover {
    position: relative;
    top: -5px;
    color: #725346;
  }

  .header-dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 125%;
    width: 150px;
    right: 0;
    background-color: #222;
    z-index: 1;
  }
  
  .header-dropdown-content a {
    padding: 10px;
    margin: 0;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
  }
  
  .header-dropdown-content a:hover {
    background-color: #725346;
  }

 .header-path {
    margin-left: 15px;
    color: #fff;
}