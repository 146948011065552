/* LoginForm.css */
.login-body {
  background-color: #fff;
  min-height: 100vh;
  letter-spacing: 0.1px;
  display: flex;
  padding-right: 3vw;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: calc(7px + 2vmin);
  color: #222;
  margin-bottom: 0px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

.legal-a {
  font-size: 0.8rem;
}
  
  .login-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-h2 {
    text-align: center;
    color: #222;
  }

  .login-h4 {
    text-size-adjust: 5px;
  }
  
  .login-formgroup {
    margin-bottom: 15px;
  }
  
  .login-label {
    padding: 10px;
    padding-left: 0px;
    margin: 0;
    color: #222;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
  }
  
  .login-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
  }

  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #222;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
  }
